import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IsBrowserService } from './is-browser-service.service';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private isBrowserService = inject(IsBrowserService);
  private platformId = inject<Object>(PLATFORM_ID);

  private readonly localStorage: any;

  constructor() {
    const platformId = this.platformId;

    if (isPlatformBrowser(platformId)) {
      this.localStorage = window.localStorage;
    } else {
      this.localStorage = null;
    }
  }

  getItem(key: string): any {
    if (this.localStorage) {
      return this.localStorage.getItem(key);
    }
    return null;
  }

  setItem(key: string, value: any): void {
    if (this.localStorage) {
      this.localStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.localStorage) {
      this.localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (this.localStorage) {
      this.localStorage.clear();
    }
  }
}
